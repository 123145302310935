import logger from '~/services/logger';
import { Fragment, useEffect } from 'react';
import NewNavBar, {
  getCurrentNavBar,
} from '~/services/plugins/nav-bar/components';
import {
  useFetcher,
  useLoaderData,
  useLocation,
  type ClientLoaderFunctionArgs,
} from '@remix-run/react';
import { json, redirect } from '@remix-run/node';
import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from '@remix-run/node';

import {
  cssVarsMetaFunctionData,
  getThemeVars,
} from '~/services/css-vars/index.ts';
import { getDebugMode } from '~/services/debug/index.server.ts';
import { getConfig, getConfigsCaching } from '~/services/config/index.ts';
import type { NavigationHandler } from '~/services/layout/navigations.ts';
import {
  getNavItems,
  navigationHandler,
} from '~/services/layout/navigations.ts';
import {
  getLoginScreen,
  getRedirectUrlFromQueryParams,
} from '~/services/layout/index.server.ts';
import { getOAuthLoginScreen } from '~/services/layout/index.ts';
import { getHash } from '~/utils/hash.ts';
import { getServerIsLoggedIn } from '~/services/login/index.ts';
import NavBar from '~/components/navigation/nav-bar';
import type { FooterData } from '~/components/navigation/footer.tsx';
import Footer from '~/components/navigation/footer.tsx';
import loginFlowAuthenticator from '~/services/login/login-flow.ts';
import { sessionAuthKey } from '~/services/login/session-auth-key';
import { getStaticLinks } from '~/services/router/index.server';
import { isLoggedInClientState } from '~/utils/is-logged-in-client-state';
import { sessionCookie } from '~/services/session/session.server';
import type { ZappNavigation } from '~/services/layout/types';
import { getSupportedLanguages, getURLLanguage } from '~/utils/i18n';

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return cssVarsMetaFunctionData({
    data,
    routePath: 'css-vars',
  });
};

export async function loader({ request }: LoaderFunctionArgs) {
  const url: URL = new URL(request.url);

  const { debug, debugLayoutId } = getDebugMode(url);

  const { ttl, swr } = getConfigsCaching();

  const { layout, cellStyles, pluginConfigurations } = await getConfig(
    { ttl, swr },
    debugLayoutId
  );

  const { id, screens, navigations } = layout;

  const urlLanguage = getURLLanguage(url.pathname);

  const screenNavigations: NavigationHandler = navigationHandler({
    navigations,
    screens,
    urlPathname: url.pathname,
    urlLanguage,
  });

  const footerData: { footerNavItems: FooterData[]; id: string } | undefined =
    getNavItems({
      navigations,
      navigationType: 'footer',
      screens,
      urlPathname: url.pathname,
    });

  const themeVars = getThemeVars(pluginConfigurations);

  // TODO: move to a single function
  const hash = getHash(
    JSON.stringify({
      layout,
      cellStyles,
      themeVars,
      APP_VERSION_UUID: process.env.APP_VERSION_UUID,
      programmatic: process.env.PROGRAMMATIC ? Date.now() : false,
    })
  );

  const { session } = await sessionCookie(request, pluginConfigurations);

  const oauthLoginScreen = getOAuthLoginScreen(screens);

  const redirectBack = getRedirectUrlFromQueryParams(request.url);

  if (oauthLoginScreen) {
    return redirect(`/oauth?redirectBack=${encodeURIComponent(redirectBack)}`);
  }

  const authInfo = session.get(sessionAuthKey);

  const isLoggedIn: boolean = getServerIsLoggedIn(authInfo);

  if (isLoggedIn) {
    return redirect('/');
  }

  const screen = getLoginScreen(screens);

  if (!screen) return redirect('/');

  // TODO: maybe move to authenticate()
  const loginEndpoint = screen?.general?.login_endpoint;

  if (!loginEndpoint) throw Error('No login endpoint');

  const options = {
    method: 'GET',
    headers: {
      'x-applicaster-screen-id': screen.id,
      'x-applicaster-layout-id': id,
    },
  };

  const res = await fetch(loginEndpoint, options);
  const loginUrl = new URL(res.url);
  const overridePath = url.searchParams.get('overridePath');
  const resetUsingToken = url.searchParams.get('resetUsingToken');
  const resetPasswordToken = url.searchParams.get('resetPasswordToken');

  let overrideURL;
  if (overridePath && resetUsingToken && resetPasswordToken) {
    overrideURL = new URL(`./${overridePath}`, loginUrl.href);
    loginUrl.pathname = overridePath;
    overrideURL.searchParams.set('resetUsingToken', resetUsingToken);
    overrideURL.searchParams.set('resetPasswordToken', resetPasswordToken);
  }

  if (!res.ok) throw Error('Fetch login endpoint failed');
  const newNavigations = Object.entries(navigations).reduce(
    (acc, [id, nav]) => {
      acc[id] = nav as ZappNavigation;
      return acc;
    },
    {} as Record<string, ZappNavigation>
  );

  const navBar = getCurrentNavBar(screen as any, newNavigations);

  return json({
    isResponsiveNavBar: navBar?.navigation_type === 'responsive_nav_bar',
    navBar,
    loginUrl: overrideURL ? overrideURL.href : loginUrl.href,
    navigations: screenNavigations,
    footerData,
    debug,
    debugLayoutId,
    screen,
    hash,
    staticLinks: getStaticLinks(screens, getSupportedLanguages(), urlLanguage),
  });
}

export async function action({ request }: ActionFunctionArgs) {
  const { ttl, swr } = getConfigsCaching();
  const {
    layout: { screens },
    pluginConfigurations,
  } = await getConfig({ ttl, swr });
  const screen = getLoginScreen(screens);
  const redirectTo: string = getRedirectUrlFromQueryParams(request.url);

  await loginFlowAuthenticator(pluginConfigurations, screen).authenticate(
    'login-flow',
    request,
    {
      successRedirect: redirectTo,
    }
  );
}

export const clientLoader = async ({
  serverLoader,
}: ClientLoaderFunctionArgs) => {
  await isLoggedInClientState().remove();
  const serverLoaderData: any = await serverLoader();
  //  Will only happen in login flow from here

  await isLoggedInClientState().set(false);

  return serverLoaderData;
};

clientLoader.hydrate = true;

export default function Login(): JSX.Element {
  const location = useLocation();
  const loaderData = useLoaderData<typeof loader>();
  const fetcher = useFetcher();
  const loginUrl = loaderData?.loginUrl;

  const handleMessage = async (event: { data: string | Blob }) => {
    if (hasAccessToken(event.data)) {
      const data = new FormData();
      data.append('json', event.data);

      fetcher.submit(data, { method: 'post' });

      await isLoggedInClientState().set(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [fetcher, location.search]);

  return (
    <Fragment key={location.pathname}>
      {loaderData?.isResponsiveNavBar ? <NewNavBar /> : <NavBar />}

      <div className="h-screen">
        <iframe
          name="frame-login"
          id="frame-login"
          sandbox="allow-same-origin allow-scripts allow-forms allow-top-navigation allow-popups"
          title="login"
          src={loginUrl}
          className="h-full w-full"
        />
      </div>
      <Footer />
    </Fragment>
  );
}

function hasAccessToken(data: string | Blob) {
  try {
    if (typeof data !== 'string') {
      return false;
    }

    return !!JSON.parse(data)?.access_token;
  } catch (e) {
    logger.info(`#hasAccessToken: ${e}`);
  }
}
